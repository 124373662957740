import { useMemo, useState } from "react";
import { useTranslation } from "next-i18next";

import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { InputSearchProps } from "@sellernote/_sds-v2/src/components/form/InputSearch";
import PlaneIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlaneIcon";
import Ship3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Ship3Icon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import {
  createSchedulePortOption,
  getPortType,
  getRecommendCategoryOptionList,
} from "../utils";

import InputSearchWithPortOptions, {
  PortInfoType,
} from "../../../components/InputSearchWithPortOptions";
import useGetPortOptions from "../../../components/InputSearchWithPortOptions/hooks/useGetPortOptions";
import {
  checkIsWarehouse,
  getPortLabel,
} from "../../../components/InputSearchWithPortOptions/utils";

import { SCHEDULE_PORT_CATEGORY_INFO_LIST } from "../constants";
import { useSearch } from "../SearchContext";

interface Props {
  isMobile?: boolean;
}

export default function Pod({ isMobile = false }: Props) {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { t } = useTranslation("page-forwarding-schedule");

  const [{ freightType, polId, podId }, setSearch] = useSearch();

  const {
    rawPortList,
    portOptionList,
    isLoading: isLoadingPortOptionList,
  } = useGetPortOptions({
    pointType: "end",
    portType: getPortType(freightType),
    searchTerm: debouncedSearchTerm,
    categoryInfoList: SCHEDULE_PORT_CATEGORY_INFO_LIST,
    transformToPortOption: createSchedulePortOption,
  });

  const selectedPod = useMemo(() => {
    if (!podId) return undefined;

    return rawPortList.find((port) => port.id === podId);
  }, [podId, rawPortList]);

  const podRecommendationList = useMemo(() => {
    if (debouncedSearchTerm) return undefined;

    return getRecommendCategoryOptionList({
      portList: rawPortList,
      freightType,
      locationType: "pod",
      portCountryOfAnotherLocationType: rawPortList?.find(
        (port) => port.id === podId
      )?.country,
    });
  }, [freightType, podId, rawPortList, debouncedSearchTerm]);

  const handlePolSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: searchTerm } = e.target;

    setSearchTerm(searchTerm);
  };

  const handlePodSelect = (portInfo: PortInfoType) => {
    if (checkIsWarehouse(portInfo) || !portInfo) return;

    const { id: podId, portGroupId: podPortGroupId } = portInfo;

    setSearch({
      podId,
      podPortGroupId,
    });
  };

  const handlePodReset = () => {
    setSearch({ podId: null, podPortGroupId: null });
    setSearchTerm("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isBackspaceKey = e.key === "Backspace";

    if (!selectedPod || !isBackspaceKey) return;

    handlePodReset();
  };

  const isLoading = (() => {
    if (!searchTerm || Boolean(selectedPod)) return false;

    return searchTerm !== debouncedSearchTerm || isLoadingPortOptionList;
  })();

  const inputValue = (() => {
    if (!selectedPod) return searchTerm;

    return getPortLabel(selectedPod);
  })();

  const leftIconInfo: InputSearchProps["leftIconInfo"] = (() => {
    if (!selectedPod) return undefined;

    return {
      color: COLOR.bk_80,
      Icon: selectedPod.type === "sea" ? Ship3Icon : PlaneIcon,
    };
  })();

  return (
    <InputSearchWithPortOptions
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            {t("page-forwarding-schedule:도착지")}
            <span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      leftIconInfo={leftIconInfo}
      placeholder={t("page-forwarding-schedule:항구/공항을_입력해주세요")}
      onChange={handlePolSearchTermChange}
      isLoading={isLoading}
      searchTerm={inputValue}
      searchSourceList={portOptionList}
      focusSearchSourceList={podRecommendationList}
      onSelect={handlePodSelect}
      onReset={handlePodReset}
      onKeyDown={handleKeyDown}
    />
  );
}
