import { useRef } from "react";

import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import Eta from "../components/Eta";
import Etd from "../components/Etd";
import Pod from "../components/Pod";
import Pol from "../components/Pol";
import Search from "../components/Search";

import { SearchProvider } from "../SearchContext";
import FreightType from "./FreightType";
import Styled from "./index.styles";

export default function ScheduleSearchBarForMobile() {
  const polOptionHandlerListRef =
    useRef<InputSearchWithOptionsHandlerList>(null);
  const podOptionHandlerListRef =
    useRef<InputSearchWithOptionsHandlerList>(null);

  return (
    <SearchProvider>
      <Styled.searchBar className="schedule-search-bar-for-mobile">
        <Styled.searchConditionList>
          <FreightType />

          <Pol isMobile />

          <Pod isMobile />

          <Styled.partContainer>
            <Etd isMobile />

            <Eta isMobile />
          </Styled.partContainer>
        </Styled.searchConditionList>

        <Search
          polOptionHandlerListRef={polOptionHandlerListRef}
          podOptionHandlerListRef={podOptionHandlerListRef}
          isMobile
        />
      </Styled.searchBar>
    </SearchProvider>
  );
}
